<template>
	<div class="story">

		<LandingpageHeader activeTab="story"></LandingpageHeader>

		<!-- HEADER -->

		<div class="landingpage__header">

			<div class="landingpage__header__text">
				<h1>Der Weg in eine gesunde & sportliche <span>Zukunft!</span></h1>
			</div>

			<div class="landingpage__header__image">
				<img src="@/assets/landingpage/story-header.jpg" srcset="@/assets/landingpage/story-header@2x.jpg 2x" alt="Story SportlerPlus">
			</div>

		</div>


		<!-- ZIEL / MISSION -->

		<div class="story__intro">

			<div class="story__intro__item">
				<img class="story__intro__item__icon" src="@/assets/landingpage/ic_ziel.svg" alt="Unsere Mission">
				<h3>Unsere Mission</h3>
				<p>Dir zu helfen, deine Leistung und Gesundheit zu verbessern. Mit sportartspezifischen und allgemeinen Workouts, Rezepten, Experten-Know-How und alltagstauglichen Tipps rund um Training, Ernährung und Gesundheit.</p>
			</div>

			<div class="story__intro__item">
				<img class="story__intro__item__icon" src="@/assets/landingpage/ic_expertise.svg" alt="Unsere Expertise">
				<h3>Unsere Expertise</h3>
				<p>Hinter SportlerPlus stehen über 20 Jahre Erfahrung in der Amateursportszene und der (Sport-) Medienbranche. Unser Team aus Experten rund um Training & Ernährung verbindet die Leidenschaft für Sport, Ernährung & einen aktiven Lebensstil.</p>
			</div>

		</div>


		<!-- WARUM SPORTLERPLUS? -->

		<div class="story__quote">

			<div class="story__quote__content">

				<h2>Warum SportlerPlus?</h2>

				<p>„Ein Blick in die Amateursportszene offenbart schnell: Bei aller Leidenschaft, allem Teamgeist und allem Ehrgeiz fehlt oft fundiertes Wissen über Trainings- und Ernährungsoptimierung.</p>
				<p>Dabei bergen genau diese beiden Bereiche so viel <b>Potenzial für mehr Gesundheit, Leistung und Freude am Sport.</b> Wie halte ich mich fit und bringe dies in Einklang mit der richtigen Ernährung? Wie nehme ich ab ohne mich in meiner Lebensweise von Diäten verbiegen zu lassen? Wie passt das in meinen Tagesablauf mit <b>Job, Familie, Freunde und Freizeit?</b> Und wie kann ich meine Leistungsfähigkeit verbessern?</p>
				<p>Auf diese Fragen möchten wir Antworten geben und jedem Sportler einen einfachen und verständlichen Zugang zu den Themen Training, Ernährung und Supplements ermöglichen.“</p>

				<div class="story__quote__user">
					<img class="story__quote__user__icon" src="@/assets/landingpage/quote-avatar.png" srcset="@/assets/landingpage/quote-avatar@2x.png 2x" alt="Marcel Hager">
					<div class="story__quote__user__content">
						<div class="story__quote__user__label">Marcel Hager</div>
						<div class="story__quote__user__sublabel">CEO & Founder</div>
					</div>
				</div>

			</div>

		</div>


		<!-- HEIMAT ALLER SPORTARTEN -->

		<div class="story__sports">

			<div class="story__sports__image">
				<img src="@/assets/landingpage/home-of-sports.jpg" srcset="@/assets/landingpage/home-of-sports@2x.jpg 2x" alt="SportlerPlus Sportarten">
			</div>

			<div class="story__sports__text">
				<h2>Die Heimat aller Sportarten</h2>
				<p>SportlerPlus gehört zur Produktfamilie von SPM Sportplatz Media und hat den aktiven Sportler im Fokus. Der Zugang zu Deutschlands größter Amateursport-Community ermöglicht uns einen täglichen Austausch mit Sportlern in ganz Deutschland. Dadurch können wir relevante Trainings- und Ernährungs-Inhalte perfekt auf unsere Community abstimmen und diese sogar in unsere Entwicklungsprozesse mit einbeziehen. Mit voller Motivation gehen wir täglich auf die Bedürfnisse unserer Sportler ein, um dir das bestmögliche Produkt zur Steigerung der Leistungsfähigkeit anzubieten – von Sportlern für Sportler!</p>
			</div>

		</div>

		<LandingpageFooter></LandingpageFooter>

	</div>
</template>

<script>
	import LandingpageHeader from "@/components/LandingpageHeader.vue";
	import LandingpageFooter from "@/components/LandingpageFooter.vue";

	export default {
		name: 'Story',
		components: { LandingpageHeader, LandingpageFooter },
		methods: { 
			scrollToTop() {
				window.scrollTo({
					top: 0,
					left: 0,
					/* TODO: Smooth Transition doesn't work */
					behavior: 'smooth'
				});
			}
		},
	}
</script>

<style lang="scss">

	.story {
		position: relative;
		background: $colorWhite;

		nav {
			z-index: 2;

			@media screen and (min-width: 768px) {
				background: none;
				position: relative;
			}
		}

		footer {
			box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);
		}

		&__intro {
			position: relative;
			z-index: 2;
			max-width: 1088px;
			margin: 0px auto 72px;
			padding: 0 16px;

			@media screen and (min-width: 768px) {
				margin: 0px auto 80px;
				padding: 0 24px;
				display: flex;
				justify-content: space-between;
			}

			&__item {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 24px;

				@media screen and (min-width: 768px) {
					display: block;
					width: calc(50% - 96px);

					&:nth-child(2) {
						margin-right: 96px;
					}
				}

				&__icon {
					margin: -6px 8px 3px -9px;
				}

				h3 {
					font-size: 17px;
					margin-bottom: 9px;

					@media screen and (min-width: 768px) {
						font-size: 24px;
					}
				}

				p {
					width: 100%;
					margin-top: 3px;

					@media screen and (min-width: 768px) {
						margin-top: 0;
					}
				}
			}
		}

		&__quote {
			position: relative;
			z-index: 2;
			border-top: 1px solid rgba($colorBlack, .08);
			border-bottom: 1px solid rgba($colorBlack, .08);
			padding: 94px 0 72px;

			@media screen and (min-width: 768px) {
				padding: 152px 0 136px;
			}

			&__content {
				position: relative;
				max-width: 860px;
				margin: 0px auto;
				padding: 0 16px;

				&:before {
					content: '“';
					position: absolute;
					font-size: 300px;
					font-weight: 600;
					color: rgba($colorPrimary, .12);
					top: -150px;
					left: -4px;

					@media screen and (min-width: 768px) {
						font-size: 500px;
						top: -232px;
						left: -92px;
					}
				}

				h2 {
					margin-bottom: 31px;

					@media screen and (min-width: 768px) {
						font-size: 32px;
					}
				}

				p {
					font-size: 17px;
					line-height: 32px;
					opacity: 1;
					margin-bottom: 12px;
				}
			}

			&__user {
				display: flex;
				align-items: center;
				margin-top: 22px;

				&__icon {
					margin: 0 8px 0 -10px;
				}

				&__content {
					display: flex;
					flex-direction: column;
				}

				&__label {
					font-size: 17px;
					font-weight: 700;
					line-height: 24px;
					color: $colorBlack;
				}

				&__sublabel {
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					color: rgba($colorBlack, .64);
				}
			}
		}

		&__sports {
			display: flex;
			flex-direction: column;
			max-width: 1088px;
			margin: 0px auto 80px;
			padding: 96px 16px 0;

			@media screen and (min-width: 768px) {
				padding: 120px 24px 64px;
				align-items: center;
				flex-direction: row;
			}

			&__image {
				display: flex;
				justify-content: flex-end;
				width: calc(100% + 32px);
				margin: 0 -16px 8px;

				@media screen and (min-width: 768px) {
					margin: 0 48px 0 0;
					width: 50%;
				}

				img {
					margin-left: -22px;
					width: 100%;
					height: 100%;

					@media screen and (min-width: 768px) {
						width: auto;
					}
				}
			}

			&__text {

				@media screen and (min-width: 768px) {
					width: 50%;
				}

				h2 {
					max-width: 400px;
					margin-bottom: 17px;
					margin-top: 16px;

					@media screen and (min-width: 768px) {
						margin-bottom: 25px;
						margin-top: 0;
					}
				}
			}

		}
	}
</style>